<template>
  <div class="page-container">
    <div class="flex-column-center login-header">
      <img src="./../../assets/logo.png" class="logo" alt="">
      <p class="flex-center login-title">设备生产测试</p>
    </div>
    <md-field>
      <md-input-item
          ref="name"
          v-model="username"
          title="账号"
          placeholder="请输入账号名称"
          is-title-latent
      ></md-input-item>
      <md-input-item
          ref="pwd"
          type="password"
          v-model="password"
          title="密码"
          placeholder="请输入密码"
          is-title-latent
      ></md-input-item>
    </md-field>
    <md-button type="primary" class="login-btn" @click="doLogin">登录</md-button>

  </div>
</template>

<script>
import axios from "axios";
import Api from './../../assets/config/api.config';
import {Toast} from "mand-mobile";

export default {
  name: "Login",
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    doLogin() {
      if(this.username && this.password) {
        let params = new FormData();
        params.append('username', this.username);
        params.append('password', this.password);
        axios.post(Api.login, params, this.$headers).then(res => {
          if(res.data.code === 200) {
            Toast.info('登录成功，请稍后');
            localStorage.setItem('user', this.username);
            this.$router.push({path: '/'});
          }else {
            Toast.info(res.data.msg)
          }
        })
      }else {
        Toast.info('请输入账号和密码')
      }

    }
  }
}
</script>

<style lang="less" scoped>
@primary: #13c2c2;
.logo {
  width: 3rem;
  margin: 1rem 0;
}
.login-btn {
  margin: 1rem 0;
}
.md-button.primary {
  background: @primary;
}
.md-button.primary:after {
  border: none;
}
</style>
